const LOCAL_DEV = process.env.NEXT_PUBLIC_USE_LAB === 'true' || false; // set to true when pointing to a local server

const getNeptuneUrl = (env, endpoint, onlyBase = false) => {
    const base =
        !LOCAL_DEV || endpoint.includes('auth-service')
            ? `https://neptune-api-${env}.arena-ai.com`
            : 'https://localhost.arena-ai.com:8000';

    const url = onlyBase ? `${base}${endpoint}` : `${base}/neptune/api/neptune-server/${endpoint}`;
    return url;
};

export default getNeptuneUrl;
