import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type LocalStorageValue<T> = T | null;

function useLocalStorageState<T>(
    key: string,
    initialValue: LocalStorageValue<T>,
): [LocalStorageValue<T>, Dispatch<SetStateAction<LocalStorageValue<T>>>] {
    const getInitialValue = () => {
        let storedValue;
        if (typeof window !== 'undefined') {
            storedValue = localStorage.getItem(key);
        }

        return storedValue ? JSON.parse(storedValue) : initialValue;
    };

    const [value, setValue] = useState<LocalStorageValue<T>>(getInitialValue);

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
}

export default useLocalStorageState;
